import { jsx as _jsx } from "react/jsx-runtime";
import Accordion from "cms/components/front-office/contents/Accordion";
import Text from "cms/components/front-office/contents/Text";
import uuidv4 from "uuid/v4";
var details = _jsx(Text, { children: "Dans cette zone vous serez libre d'ajouter diff\u00E9rents contenus" });
var panels = {
    summary: "Panneau dépliant",
    details: details,
    id: uuidv4()
};
var ExpansionPanelPreview = function () {
    return _jsx(Accordion, { panels: panels });
};
export default ExpansionPanelPreview;
