import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect } from "react";
import ImageGallery from "react-image-gallery";
var ImagesGallery = function (props) {
    var _a = props.images, images = _a === void 0 ? { image: [] } : _a, _b = props.height, height = _b === void 0 ? "200" : _b, _c = props.infinite, infinite = _c === void 0 ? true : _c, _d = props.showNav, showNav = _d === void 0 ? true : _d, _e = props.showThumbnails, showThumbnails = _e === void 0 ? false : _e, _f = props.thumbnailPosition, thumbnailPosition = _f === void 0 ? "bottom" : _f, _g = props.showFullscreenButton, showFullscreenButton = _g === void 0 ? false : _g, _h = props.showPlayButton, showPlayButton = _h === void 0 ? false : _h, _j = props.isRTL, isRTL = _j === void 0 ? false : _j, _k = props.showBullets, showBullets = _k === void 0 ? true : _k, _l = props.showIndex, showIndex = _l === void 0 ? false : _l, _m = props.autoPlay, autoPlay = _m === void 0 ? true : _m, _o = props.slideInterval, slideInterval = _o === void 0 ? "3000" : _o;
    var _p = React.useState(null), imgPlaceholder = _p[0], setImgPlaceholder = _p[1];
    var placeholderImgRef = React.useCallback(function (node) {
        if (node !== null) {
            setImgPlaceholder(node.getBoundingClientRect());
        }
    }, []);
    var currentImages = Array.isArray(images.image) ? images.image : [images.image];
    var items = currentImages.map(function (currentImage) {
        var file = currentImage.file;
        return {
            original: file === null || file === void 0 ? void 0 : file.url,
            originalAlt: file === null || file === void 0 ? void 0 : file.alt,
            originalTitle: file === null || file === void 0 ? void 0 : file.title,
            originalHeight: parseInt(height, 10),
            thumbnail: (file === null || file === void 0 ? void 0 : file.publicUrlBySize) ? file === null || file === void 0 ? void 0 : file.publicUrlBySize[Object.keys(file === null || file === void 0 ? void 0 : file.publicUrlBySize)[0]] : file === null || file === void 0 ? void 0 : file.url,
            thumbnailAlt: file === null || file === void 0 ? void 0 : file.alt,
            srcSet: file === null || file === void 0 ? void 0 : file.srcSet,
            sizes: imgPlaceholder ? "".concat(imgPlaceholder.width, "px") : undefined
        };
    });
    useEffect(function () {
        if (window) {
            // eslint-disable-next-line global-require
            require("react-image-gallery/styles/css/image-gallery.css");
        }
    }, []);
    return imgPlaceholder ? (_jsx(ImageGallery, { items: items, lazyLoad: true, infinite: infinite, showNav: showNav, showThumbnails: showThumbnails, thumbnailPosition: thumbnailPosition, showFullscreenButton: showFullscreenButton, showPlayButton: showPlayButton, isRTL: isRTL, showBullets: showBullets, showIndex: showIndex, autoPlay: autoPlay, slideInterval: parseInt(slideInterval, 10) })) : (_jsx("div", { ref: placeholderImgRef, style: { height: "100%" } }));
};
export default ImagesGallery;
