var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Grid, Paper, Typography, useMediaQuery, useTheme } from "@mui/material";
import { styled } from "@mui/system";
import ContactForm from "./ContactForm";
import ContactInformations from "./ContactInformations";
var paperStyled = {
    position: "relative",
    display: "flex",
    flexDirection: "row",
    bgcolor: "#fff",
    minHeight: "576px",
    px: 4,
    py: 5,
    borderRadius: 2,
    boxShadow: "0px 4px 8px rgba(102, 109, 146, 0.08)"
};
var paperMobileStyled = {
    display: "flex",
    flexDirection: "column",
    bgcolor: "#fff",
    mt: 2,
    p: 3,
    borderRadius: 1,
    boxShadow: "0px 4px 8px rgba(102, 109, 146, 0.08)"
};
var backgroundStyled = {
    position: "absolute",
    top: 160,
    left: -0,
    zIndex: "-1",
    width: "100%",
    height: "calc(100% - 180px)",
    backgroundImage: "url(\"/RectangleBackground.svg\")",
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat"
};
var imageStyled = {
    display: "block",
    position: "absolute",
    top: -33,
    right: -40,
    width: "205px",
    height: "144px"
};
var MainStyled = styled("main")({
    paddingTop: 64
});
var MainStyledMobile = styled("main")({
    paddingTop: 22
});
var ContactFormWithContents = function (props) {
    var page = props.page;
    var title = page.title, _a = page.shortDescription, shortDescription = _a === void 0 ? "" : _a, contents = page.contents;
    var _b = contents.subtitle, subtitle = _b === void 0 ? "" : _b;
    return (_jsxs(Box, { sx: { display: "flex", flexDirection: "column" }, children: [_jsx(Typography, { variant: "h0", component: "h1", sx: { "& > pre": { my: 0 }, order: 2 }, children: title }), _jsx(Typography, { variant: "h1", component: "div", sx: { div: { color: "greyColor.main", fontWeight: 400 }, order: 1 }, children: subtitle }), _jsx(Box, { sx: { m: 0, order: 3 }, children: shortDescription }), _jsx(Box, { sx: { order: 4 }, children: _jsx(ContactForm, {}) })] }));
};
var ContactTemplate = function (props) {
    var page = props.page;
    var contents = page.contents;
    var contactLink = contents.contactLink;
    var theme = useTheme();
    var isMobile = !useMediaQuery(theme.breakpoints.up("md"));
    if (isMobile) {
        return (_jsxs(MainStyledMobile, { children: [_jsx(Paper, { id: "contact_paper_form", sx: paperMobileStyled, children: _jsx(ContactFormWithContents, __assign({}, props)) }), _jsx(Paper, { id: "contact_paper_informations", sx: paperMobileStyled, children: _jsx(ContactInformations, { contactLink: contactLink }) })] }));
    }
    return (_jsxs(MainStyled, { children: [_jsx(Box, { sx: backgroundStyled }), _jsxs(Paper, { id: "contact_paper", sx: paperStyled, children: [_jsx(Box, { id: "sending_emails_flatline_box", sx: imageStyled, children: _jsx("img", { alt: "", src: "/sending_emails_flatline.svg" }) }), _jsxs(Grid, { container: true, direction: "row", children: [_jsx(ContactInformations, { contactLink: contactLink }), _jsx(Grid, { item: true, md: 8, lg: 9, container: true, direction: "column", pl: 3.25, pr: 2, children: _jsx(ContactFormWithContents, __assign({}, props)) })] })] })] }));
};
export default ContactTemplate;
