var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import Box from "@mui/material/Box";
import classnames from "classnames";
import sanitizeHtml from "sanitize-html";
export var componentClassName = "Da-Text";
var textStyled = {
    color: "primary.dark",
    "& h2": {
        fontWeight: 600,
        fontSize: "1.25rem",
        lineHeight: "1.465rem",
        letterSpacing: "0.6px",
        mb: "0",
        mt: 0
    },
    "& h3": {
        fontSize: "1.125rem",
        fontWeight: 600,
        lineHeight: "1.32rem",
        letterSpacing: "0px",
        mb: "1.5rem",
        mt: 0
    },
    "& h4": {
        fontSize: "1rem",
        fontWeight: 600,
        lineHeight: "1.17rem",
        letterSpacing: "0px",
        mb: "1.5rem",
        mt: 0
    },
    "& h5": {
        fontSize: "1.25rem",
        lineHeight: "1.625rem",
        fontWeight: 600
    },
    "& a": {
        color: "primary.main",
        fontWeight: 600,
        textDecoration: "none"
    },
    "& p": {
        fontSize: "0.875rem",
        color: "greyColor.main",
        marginBlockStart: 0,
        marginBlockEnd: 0
    },
    "& pre": {
        whiteSpace: "pre-wrap"
    },
    "&  ul": {
        listStyle: "none",
        pl: 2,
        "& li": {
            textIndent: "-4px",
            color: "greyColor.main",
            fontSize: "0.875rem",
            "&:before": {
                content: "\"\\f111\"",
                fontWeight: "600",
                fontSize: "4px",
                verticalAlign: "middle",
                textIndent: "0",
                width: "16px",
                display: "inline-block",
                fontFamily: "\"Font Awesome 5 Pro\"",
                color: "secondary.main"
            },
            "& ul": {
                ml: 3,
                "& li": {
                    textIndent: "-22px",
                    "&:before": {
                        content: "\"\\f00c\"",
                        fontWeight: "400",
                        fontSize: "14px",
                        width: "22px",
                        color: "secondary.main"
                    }
                }
            }
        }
    }
};
var sanitizerOptions = __assign(__assign({}, sanitizeHtml.defaults), { allowedTags: sanitizeHtml.defaults.allowedTags.concat(["h2", "img", "u", "s", "sub", "address", "sup"]), allowedAttributes: __assign(__assign({}, sanitizeHtml.defaults.allowedAttributes), { "*": ["id", "class", "style", "title"], a: ["href", "name", "target"], img: ["src", "target", "alt"] }), allowedSchemesByTag: __assign(__assign({}, sanitizeHtml.defaults.allowedSchemesByTag), { img: ["https", "data"] }) });
var Text = function (props) {
    var _a = props.children, children = _a === void 0 ? null : _a, _b = props.className, className = _b === void 0 ? "" : _b, others = __rest(props, ["children", "className"]);
    var childrenProps = {};
    if (typeof children === "string") {
        childrenProps.dangerouslySetInnerHTML = { __html: sanitizeHtml(decodeURIComponent(children), sanitizerOptions) };
    }
    else {
        childrenProps.children = children;
    }
    return (_jsx(Box, __assign({ sx: textStyled, className: classnames(className, componentClassName) }, childrenProps, others, { tabIndex: "-1" })));
};
export default Text;
