import { jsx as _jsx } from "react/jsx-runtime";
import CookiesConsentService, { COOKIE_CONSENT_GOOGLE_RECAPTCHA_NAME } from "cms/services/CookiesConsentService";
import React, { useContext, useState } from "react";
var CookiesContext = React.createContext(undefined);
export var useCookiesContext = function () {
    var context = useContext(CookiesContext);
    if (!context)
        throw new Error("CookiesContextProvider isn't defined");
    return context;
};
export var CookiesContextProvider = function (_a) {
    var children = _a.children;
    var _b = useState(false), openCookiesModal = _b[0], setOpenCookiesModal = _b[1];
    var _c = useState(CookiesConsentService.getCookieConsent(COOKIE_CONSENT_GOOGLE_RECAPTCHA_NAME)), googleRecaptchaConsent = _c[0], setGoogleRecaptchaConsent = _c[1];
    var _d = useState(true), isDisabledBanner = _d[0], setIsDisabledBanner = _d[1];
    React.useEffect(function () {
        setIsDisabledBanner(CookiesConsentService.isDisableCookiesBanner());
    }, []);
    var handleCookiesModal = function () {
        setOpenCookiesModal(!openCookiesModal);
    };
    var disabledBanner = function () {
        CookiesConsentService.disableCookiesBanner();
        setIsDisabledBanner(true);
    };
    return (_jsx(CookiesContext.Provider, { value: {
            openCookiesModal: openCookiesModal,
            handleCookiesModal: handleCookiesModal,
            setOpenCookiesModal: setOpenCookiesModal,
            isDisabledBanner: isDisabledBanner,
            disabledBanner: disabledBanner,
            googleRecaptchaConsent: googleRecaptchaConsent,
            setGoogleRecaptchaConsent: setGoogleRecaptchaConsent
        }, children: children }));
};
