var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import PauseRoundedIcon from "@mui/icons-material/PauseRounded";
import PlayArrowRoundedIcon from "@mui/icons-material/PlayArrowRounded";
import { IconButton, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Stack } from "@mui/system";
import React, { useRef } from "react";
import { Slide } from "react-slideshow-image";
import ZoomContext from "../ZoomContext";
import SideBox from "./SideBox";
var indicators = function () { return _jsx("li", { className: "indicator" }); };
var Slider = function (props) {
    var theme = useTheme();
    var zoom = React.useContext(ZoomContext).zoom;
    var _a = React.useState(false), pauseSlides = _a[0], setPauseSlides = _a[1];
    var matches = useMediaQuery("(min-width:1453px)");
    var sliderRef = useRef(null);
    var slideImages = Object.keys(props).filter(function (slider) { return slider !== "id"; });
    var sideBoxesBottom = ["85px", "120px", "151px"];
    var divStyle = {
        backgroundSize: "cover",
        height: { xs: "306px", md: "".concat(471 * zoom, "px") },
        backgroundColor: "#3b29ab"
    };
    function handleKeypress(e) {
        if (sliderRef && sliderRef.current) {
            switch (e.code) {
                case "ArrowRight":
                    sliderRef.current.goNext();
                    break;
                case "ArrowLeft":
                    sliderRef.current.goBack();
                    break;
                case "Space":
                    e.preventDefault();
                    setPauseSlides(!pauseSlides);
                    break;
            }
        }
    }
    return (_jsx(_Fragment, { children: _jsx(Box, { sx: {
                display: "flex",
                borderTop: 2,
                borderTopColor: "#263375",
                position: "relative",
                boxShadow: theme.professor ? null : "10px 10px 0px 0px #3B29AB"
            }, children: _jsxs(Box, { sx: { position: "relative", minWidth: "200px" }, children: [sideBoxesBottom.map(function (bottom, index) {
                        var _a, _b, _c;
                        return (_jsx(SideBox, { bottom: bottom, fillBox: index === 0 ? true : false, matches: matches, classes: (_c = (_b = (_a = theme.components) === null || _a === void 0 ? void 0 : _a.SideBox) === null || _b === void 0 ? void 0 : _b.styleOverrides) === null || _c === void 0 ? void 0 : _c.root }, index));
                    }), theme.professor && (_jsx(Box, { sx: {
                            position: "absolute",
                            width: "296px",
                            height: "169px",
                            borderRadius: "6px",
                            border: 1,
                            borderColor: "#3B29AB",
                            left: "-15px",
                            bottom: "-12px",
                            display: matches ? "inherit" : "none"
                        } })), _jsx(IconButton, { disableTouchRipple: true, sx: {
                            position: "absolute",
                            bottom: "8px",
                            right: "16px",
                            zIndex: "250",
                            color: " #F7FAF9",
                            p: 0,
                            "&:hover": {
                                backgroundColor: "transparent"
                            },
                            "& svg": {
                                width: "24px",
                                height: "24px"
                            }
                        }, onClick: function () { return setPauseSlides(!pauseSlides); }, children: !pauseSlides ? _jsx(PauseRoundedIcon, {}) : _jsx(PlayArrowRoundedIcon, {}) }), _jsx("div", { tabIndex: 0, onKeyDown: handleKeypress, children: _jsx(Slide, { indicators: indicators, arrows: false, easing: "ease", autoplay: !pauseSlides, ref: sliderRef, children: slideImages.map(function (slideImage, index) {
                                var _a;
                                return (_jsxs(Box, { sx: __assign(__assign({}, divStyle), { position: "relative" }), children: [_jsx(Box, { component: "img", sx: __assign(__assign({}, divStyle), { position: "relative", width: "100%", userSelect: "none", objectFit: "cover" }), alt: "", src: (_a = props[slideImage].image) === null || _a === void 0 ? void 0 : _a.props.file.url, draggable: false }), _jsx(Box, { sx: {
                                                height: "100%",
                                                position: "absolute",
                                                top: 0,
                                                width: "100%",
                                                background: "linear-gradient(179.29deg, rgba(0, 0, 0, 0) 0.66%, rgba(0, 0, 0, 0) 32.55%, rgba(0, 0, 0, 0.65) 72.17%, rgba(0, 0, 0, 0.78) 99.44%)"
                                            }, children: _jsxs(Box, { component: "a", href: props[slideImage].fullPath, sx: {
                                                    left: { lg: "64px", xs: "20px" },
                                                    bottom: { lg: "44px", xs: "20px" },
                                                    position: "absolute",
                                                    color: "white"
                                                }, children: [props[slideImage].tags && (_jsx(Stack, { direction: "row", spacing: 1, children: props[slideImage].tags.map(function (tag, index) { return (_jsx(Box, { sx: { bgcolor: "#3B29AB", borderRadius: "4px", width: "fit-content" }, children: _jsx(Typography, { variant: "caption", sx: { display: "block", color: "#F4F7FE", p: "2px 8px", fontWeight: "500" }, children: tag }) }, index)); }) })), _jsx(Typography, { sx: {
                                                            color: "#F4F7FE",
                                                            fontSize: {
                                                                lg: "1.875rem",
                                                                md: "1.25rem",
                                                                xs: "1rem"
                                                            },
                                                            fontWeight: 700,
                                                            lineHeight: {
                                                                lg: "2.53rem",
                                                                md: "1.91rem",
                                                                xs: "1.27rem"
                                                            },
                                                            pt: { lg: "6px", xs: "10px" },
                                                            letterSpacing: "0.05em"
                                                        }, children: props[slideImage].title }), _jsx(Typography, { sx: { color: "#F4F7FE", fontWeight: "500", pt: "6px", letterSpacing: "0.05em" }, children: props[slideImage].description + "..." })] }) })] }, index));
                            }) }) })] }) }) }));
};
export default Slider;
