var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Stack, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import PartnerTabs from "projects/EVIDENCEB/components/Partenaires/PartnerTabs";
import PartenaireElementsGraphique from "./PartenaireElementsGraphique";
var descriptionStyled = {
    pt: "8px",
    minWidth: { xs: "100%", md: "600px" },
    "& p": {
        fontSize: { xs: "0.75rem", md: "1rem" },
        color: "primary.dark",
        lineHeight: "1.17rem",
        letterSpacing: "0.05rem",
        fontWeight: 600
    }
};
var imageContainerStyled = {
    backgroundColor: "white",
    height: "96px",
    display: { xs: "none", lg: "inline" }
};
var Partenaires = function (props) {
    var id = props.id, title = props.title, description = props.description, tabs = props.tabs;
    return (_jsxs(_Fragment, { children: [_jsx(PartenaireElementsGraphique, {}), _jsxs(Box, { sx: { textAlign: { xs: "center", lg: "inherit" } }, children: [_jsxs(Stack, { direction: "row", justifyContent: "space-between", alignItems: "center", children: [_jsxs(Box, { sx: { pr: { xs: 0, md: 3 } }, children: [_jsx(Typography, { variant: "h5", component: "div", sx: { "& > pre": { margin: 0 } }, children: title }), _jsx(Box, { sx: descriptionStyled, children: description })] }), _jsxs(Box, { children: [_jsx(Typography, { variant: "caption", sx: { fontSize: "0.63rem", color: "#1b134d", display: "inline-block" }, children: "Impuls\u00E9 par" }), _jsx(Box, { sx: imageContainerStyled, children: _jsx(Box, { component: "img", alt: "Minist\u00E8re de l'\u00E9ducation national et de la jeunesse", src: "/Logo-header.jpeg", sx: { height: "92px", borderTop: "1px solid black" } }) })] })] }), _jsx(PartnerTabs, __assign({}, tabs))] }, id)] }));
};
export default Partenaires;
