import { jsx as _jsx } from "react/jsx-runtime";
import axios from "axios";
import PageService from "cms/services/PageService";
import CmsHooks, { HOOKS } from "cms/utils/CmsHooks";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { withRouter } from "react-router-dom";
var PageVersionContext = React.createContext({
    currentPageVersion: {},
    setCurrentPageVersion: function (page) { },
    loading: false
});
export default PageVersionContext;
axios.interceptors.request.use(function (config) {
    var _a;
    var appRoutes = CmsHooks.getHook(HOOKS.App_routes) || [];
    // Prevent call to /getPageInfos if requested pathname param is not a 'cms page'
    if (config.url === "/getPageInfos" &&
        ((_a = config.params) === null || _a === void 0 ? void 0 : _a.pathname) &&
        appRoutes.find(function (route) {
            var path = route.path, exact = route.exact;
            if (exact) {
                return config.params.pathname === path;
            }
            return config.params.pathname.startsWith(path);
        })) {
        return {
            adapter: function () { return Promise.resolve({ data: null }); }
        };
    }
    return config;
}, function (error) { return Promise.reject(error); });
export var PageVersionContextProvider = withRouter(function (props) {
    var location = props.location, children = props.children;
    var _a = useQuery(["pageversion", location.pathname], function () {
        return PageService.getPageInfos(location.pathname);
    }), page = _a.data, isFetching = _a.isFetching;
    var _b = useState(page || null), currentPageVersion = _b[0], setCurrentPageVersion = _b[1];
    var _c = useState(isFetching), loading = _c[0], setIsLoading = _c[1];
    useEffect(function () {
        if (!isFetching && page) {
            setCurrentPageVersion(page);
        }
        setIsLoading(isFetching);
    }, [page, isFetching]);
    var handleChangeCurrentVersion = function (pageVersion) {
        if (pageVersion && pageVersion.id !== (page === null || page === void 0 ? void 0 : page.id)) {
            setCurrentPageVersion(pageVersion);
            document.documentElement.scrollTop = 0;
        }
    };
    return (_jsx(PageVersionContext.Provider, { value: {
            currentPageVersion: currentPageVersion,
            setCurrentPageVersion: handleChangeCurrentVersion,
            loading: loading
        }, children: children }));
});
