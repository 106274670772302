var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { createElement as _createElement } from "react";
import { Divider } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import ImageContainer from "cms/components/front-office/contents/ImageContainer";
import { useContext } from "react";
import ZoomContext from "../ZoomContext";
var CardBox1 = function (props) {
    var id = props.id, title = props.title, description = props.description, image = props.image, others = __rest(props, ["id", "title", "description", "image"]);
    var zoom = useContext(ZoomContext).zoom;
    var styleCard = {
        bgcolor: "white",
        p: { xs: "15px", lg: "28px 32px" },
        borderRadius: "6px",
        height: { xs: "100%", lg: "".concat(312 * zoom, "px") },
        flexGrow: "1",
        boxShadow: "0px 4px 8px rgba(102, 109, 146, 0.08)",
        hyphens: "auto"
    };
    return (_createElement(Box, __assign({}, others, { key: id, sx: styleCard }),
        image && (_jsx(Box, { sx: { overflow: "hidden", width: "75%", m: "auto", "& img": { objectFit: "contain" } }, children: _jsx(ImageContainer, { ratio: 64, children: image }) })),
        _jsx(Divider, { sx: { my: 2 } }),
        title && (_jsx(Typography, { variant: "h5", component: "div", sx: { mb: 1.25, fontSize: { xs: "1.2rem", lg: "1.5rem" } }, children: title })),
        description && (_jsx(Typography, { variant: "subtitle2", component: "div", sx: { mb: 1.25, lineHeight: "16px", letterSpacing: "0.05rem", fontWeight: "500" }, children: description }))));
};
export default CardBox1;
