var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, FilledInput, FormControl, FormHelperText, InputLabel } from "@mui/material";
import useId from "@mui/utils/useId";
import { Controller } from "react-hook-form";
var inputSX = {
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "16px",
    letterSpacing: "0.05em"
};
var helperTextSXError = { color: "red", mt: "2px", lineHeight: "0.875rem" };
var helperTextSX = { mt: "2px", lineHeight: "0.875rem" };
var ContactFormField = function (props) {
    var control = props.control, required = props.required, fieldName = props.fieldName, label = props.label, icon = props.icon, _a = props.inputMaxLength, inputMaxLength = _a === void 0 ? 50 : _a, _b = props.multiline, multiline = _b === void 0 ? false : _b, regexValidation = props.regexValidation, customError = props.customError, ariaDescribedByText = props.ariaDescribedByText;
    // useId hook is only available in react18
    // so we use the hook provided by mui utils
    // @fixme : change this to react hook
    var rnd = useId();
    var id = "Contact-field" + rnd;
    return (_jsx(Controller, { name: fieldName, control: control, rules: {
            required: required,
            maxLength: inputMaxLength,
            pattern: regexValidation
        }, render: function (_a) {
            var field = _a.field, error = _a.fieldState.error;
            return (_jsxs(Box, { sx: { mb: error ? 0 : 2, mt: 1 }, children: [_jsxs(Box, { sx: {
                            display: "flex",
                            alignItems: "center",
                            borderRadius: "8px",
                            border: "1px solid",
                            borderColor: error ? "red" : "#3B29AB",
                            height: multiline ? "86px" : "56px"
                        }, children: [_jsx(Box, { sx: { display: "flex", alignItems: "center", pl: 1.5 }, children: _jsx("img", { alt: "", src: icon }) }), _jsxs(FormControl, { variant: "filled", sx: { width: "100%" }, children: [_jsx(InputLabel, { focused: false, htmlFor: id, required: required, children: label }), _jsx(FilledInput, __assign({ id: id, disableUnderline: true, multiline: multiline, value: field.value, onChange: field.onChange, sx: {
                                            backgroundColor: "transparent !important",
                                            "& .MuiFilledInput-input": inputSX
                                        }, inputProps: {
                                            maxLength: inputMaxLength,
                                            "aria-required": required,
                                            "aria-invalid": !!error
                                        } }, (ariaDescribedByText && !error
                                        ? { "aria-describedby": "ar-".concat(id) }
                                        : error
                                            ? { "aria-describedby": "error-".concat(id) }
                                            : {})))] })] }), !error && ariaDescribedByText && (_jsx(FormHelperText, { sx: helperTextSX, id: "ar-".concat(id), children: ariaDescribedByText })), error && error.type === "required" && (_jsx(FormHelperText, { sx: helperTextSXError, id: "error-".concat(id), children: (customError === null || customError === void 0 ? void 0 : customError.required) || "".concat(label, " obligatoire") })), error && error.type === "pattern" && (_jsx(FormHelperText, { sx: helperTextSXError, id: "error-".concat(id), children: (customError === null || customError === void 0 ? void 0 : customError.pattern) || "".concat(label, " invalide") }))] }));
        } }));
};
export default ContactFormField;
