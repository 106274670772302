import { jsx as _jsx } from "react/jsx-runtime";
import MuiGrid from "@mui/material/Grid";
import AdminUpdateContext from "cms/components/back-office/AdminUpdateContext";
import { useContext, useEffect, useState } from "react";
import GridItem from "./GridItem";
var Grid = function (props) {
    var _a = props.size, size = _a === void 0 ? "6" : _a, _b = props.sizeXs, sizeXs = _b === void 0 ? "12" : _b, _c = props.justify, justify = _c === void 0 ? "flex-start" : _c, _d = props.alignItems, alignItems = _d === void 0 ? "flex-start" : _d, _e = props.items, items = _e === void 0 ? [] : _e, _f = props.spacing, spacing = _f === void 0 ? "2" : _f;
    var _g = useState([]), itemsSaved = _g[0], setItemsSaved = _g[1];
    var setItemsToSaved = useContext(AdminUpdateContext).setItemsToSaved;
    useEffect(function () {
        if ((items === null || items === void 0 ? void 0 : items.length) !== (itemsSaved === null || itemsSaved === void 0 ? void 0 : itemsSaved.length)) {
            setItemsSaved(items);
        }
        else {
            setItemsToSaved("items", items, setItemsSaved);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [items]);
    if (!items || items.length === 0) {
        return null;
    }
    var arrayItems = itemsSaved;
    if (!Array.isArray(arrayItems)) {
        arrayItems = [items];
    }
    return (_jsx(MuiGrid, { container: true, role: "list", spacing: Number(spacing), justifyContent: justify, alignItems: alignItems, children: arrayItems.map(function (item, i) { return (_jsx(GridItem, { item: item, size: size, sizeXs: sizeXs }, "grid_".concat(i))); }) }));
};
export default Grid;
