import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Grid, Typography } from "@mui/material";
import SiteContext from "cms/components/shared/SiteContext";
import { useContext } from "react";
import ContactInformation from "./ContactInformation";
var ContactInformations = function (props) {
    var contactLink = props.contactLink;
    var site = useContext(SiteContext);
    var siteSettings = (site || {}).settings;
    var _a = siteSettings || {}, adress = _a.adress, city = _a.city, postalCode = _a.postalCode, mail = _a.mail, website = _a.website;
    return (_jsxs(Grid, { item: true, md: 4, lg: 3, container: true, direction: "column", children: [_jsx(ContactInformation, { icon: "/Contact-map-marker.svg", title: "Trouvez-nous", content: _jsxs(_Fragment, { children: [_jsx("div", { children: adress }), _jsxs("div", { children: [postalCode, " ", city] })] }), withDivider: true }), _jsx(ContactInformation, { icon: "/Contact-mail.svg", title: "Contactez-nous", content: mail, withDivider: true }), _jsx(ContactInformation, { icon: "/Contact-glob.svg", title: "Site web", content: website, withDivider: true }), _jsx(ContactInformation, { icon: "/Contact-admin-file.svg", title: "Administratif", 
                // TODO LINK
                content: _jsx(Typography, { variant: "text", sx: { "& a": { color: "inherit", fontStyle: "italic", textDecoration: "underline" } }, children: contactLink }) })] }));
};
export default ContactInformations;
