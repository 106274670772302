import { jsx as _jsx } from "react/jsx-runtime";
import { Alert } from "@docaposte-agility/da-design-system";
import MuiSnackbar from "@mui/material/Snackbar";
import React from "react";
export default function Snackbar(props) {
    var _a = props.open, open = _a === void 0 ? false : _a, onClose = props.onClose, _b = props.message, message = _b === void 0 ? "" : _b, _c = props.severity, severity = _c === void 0 ? "success" : _c, _d = props.duration, duration = _d === void 0 ? 3000 : _d, _e = props.anchorOrigin, anchorOrigin = _e === void 0 ? undefined : _e;
    var handleClose = React.useCallback(function (event, reason) {
        if (reason === "clickaway") {
            return;
        }
        onClose(event, reason);
    }, [onClose]);
    return (_jsx(MuiSnackbar, { anchorOrigin: anchorOrigin, open: open, autoHideDuration: duration, message: _jsx(Alert, { onClose: handleClose, severity: severity, children: message }) }));
}
