var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import classnames from "classnames";
var Icon = function (props) {
    var _a = props.icon, icon = _a === void 0 ? "" : _a, _b = props.type, type = _b === void 0 ? "fal" : _b, _c = props.className, className = _c === void 0 ? null : _c, _d = props.color, color = _d === void 0 ? undefined : _d, _e = props.background, background = _e === void 0 ? undefined : _e, _f = props.style, style = _f === void 0 ? {} : _f, others = __rest(props, ["icon", "type", "className", "color", "background", "style"]);
    return (_jsx("i", __assign({}, others, { style: __assign(__assign({ color: color || undefined }, style), ((background && {
            backgroundColor: background,
            width: "2em",
            height: "2em",
            borderRadius: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
        }) ||
            {})), className: classnames(className, "".concat(type, " fa-").concat(icon)) })));
};
export default Icon;
