var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, useMediaQuery } from "@mui/material";
import { useEffect, useState } from "react";
var ElementGraphiqueArticleGauche = function () {
    var _a = useState(8000), windowWidth = _a[0], setWindowWidth = _a[1];
    useEffect(function () {
        var handleResize = function () {
            var newWindowWidth = (-1 * (window.innerWidth - 1228)) / 2;
            setWindowWidth(newWindowWidth);
        };
        window.addEventListener("resize", handleResize);
        handleResize();
        return function () {
            window.removeEventListener("resize", handleResize);
        };
    }, []);
    var isScreenSizeValid = useMediaQuery("(min-width: 1434px)");
    var rectangleStyles = {
        position: "absolute",
        background: "#3B29AB",
        transform: "rotate(-180deg)",
        display: isScreenSizeValid ? "block" : "none"
    };
    var renderRectangle = function (width, top) {
        return (_jsx(Box, { sx: __assign(__assign({}, rectangleStyles), { width: "".concat(width, "px"), height: "1px", top: "".concat(top, "px") }) }));
    };
    return (_jsxs(Box, { sx: {
            position: "absolute",
            width: "auto",
            left: "".concat(windowWidth, "px")
        }, children: [renderRectangle(100, 15), renderRectangle(100, 19), renderRectangle(100, 23), renderRectangle(71.85, 34), renderRectangle(71.85, 38), renderRectangle(71.85, 42), renderRectangle(45.93, 53), renderRectangle(45.93, 57), renderRectangle(45.93, 61)] }));
};
export default ElementGraphiqueArticleGauche;
