var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import Box from "@mui/material/Box";
import { getFilterStyle } from "cms/utils/imageUtil";
import React from "react";
import LazyLoad from "react-lazy-load";
var Image = function (props) {
    var _a = props.file, file = _a === void 0 ? null : _a, _b = props.title, title = _b === void 0 ? "" : _b, _c = props.alt, alt = _c === void 0 ? "" : _c, _d = props.position_verticale, position_verticale = _d === void 0 ? "top" : _d, _e = props.position_horizontale, position_horizontale = _e === void 0 ? "center" : _e, _f = props.position_ajustement, position_ajustement = _f === void 0 ? "cover" : _f, _g = props.disableLazy, disableLazy = _g === void 0 ? false : _g, filters = props.filters, style = props.style;
    var _h = React.useState(null), imgPlaceholder = _h[0], setImgPlaceholder = _h[1];
    var placeholderImgRef = React.useCallback(function (node) {
        if (node !== null) {
            setImgPlaceholder(node.getBoundingClientRect());
        }
    }, []);
    var _j = file || {}, url = _j.url, srcSet = _j.srcSet;
    var img = imgPlaceholder ? (_jsx(Box, { component: "img", src: url, alt: alt || "", title: title || "", srcSet: srcSet, sizes: "".concat(imgPlaceholder.width, "px"), sx: {
            objectFit: position_ajustement,
            height: "100%",
            width: "100%",
            objectPosition: position_verticale + " " + position_horizontale
        }, style: __assign({ filter: getFilterStyle(filters) }, style) })) : (_jsx("div", { ref: placeholderImgRef, style: __assign({ height: "100%" }, style) }));
    if (disableLazy) {
        return img;
    }
    return _jsx(LazyLoad, { height: "100%", children: img });
};
export default Image;
