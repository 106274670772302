var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useMemo } from "react";
var AdminUpdateContext = React.createContext({
    updatedAt: undefined,
    updatedChildren: [],
    setItemsToSaved: function () { return undefined; }
});
export default AdminUpdateContext;
export var AdminUpdateContextProvider = function (props) {
    var children = props.children, updatedAt = props.updatedAt, childrenContents = props.childrenContents;
    var updatedChildren = useMemo(function () { return (childrenContents === null || childrenContents === void 0 ? void 0 : childrenContents.map(function (_a) {
        var key = _a.key, updatedAt = _a.updatedAt;
        return ({ key: key, updatedAt: updatedAt });
    })) || []; }, [childrenContents]);
    var setItemsToSaved = function (key, items, setItemsSaved) {
        var updatedChildrenFiltred = updatedChildren.filter(function (u) { return u.key === key; });
        setItemsSaved(function (prevItems) {
            return prevItems.map(function (prevItem, index) {
                var item = items[index];
                var updatedItem = updatedChildrenFiltred[index];
                var useNewItem = updatedItem.updatedAt && prevItem.updatedAt ? updatedItem.updatedAt.diff(prevItem.updatedAt) > 0 : true;
                return useNewItem
                    ? __assign(__assign({}, item), { updatedAt: updatedItem.updatedAt }) : __assign(__assign({}, prevItem), { updatedAt: updatedItem.updatedAt });
            });
        });
    };
    return (_jsx(AdminUpdateContext.Provider, { value: {
            updatedAt: updatedAt,
            updatedChildren: updatedChildren,
            setItemsToSaved: setItemsToSaved
        }, children: children }));
};
