var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { createElement as _createElement } from "react";
import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
// Theme/Style. ----------------------------------------------------------------
var styleCard = {
    bgcolor: "primary.light",
    p: { xs: "15px", lg: "32px" },
    borderRadius: "6px",
    height: "100%",
    display: "flex",
    flexDirection: { xs: "row", lg: "column" }
};
var PresentationBox = function (props) {
    var id = props.id, title = props.title, subTitle1 = props.subTitle1, subTitle2 = props.subTitle2, description = props.description, image = props.image, position = props.position, others = __rest(props, ["id", "title", "subTitle1", "subTitle2", "description", "image", "position"]);
    styleCard.flexDirection.xs = position;
    var theme = useTheme();
    var isMobile = !useMediaQuery(theme.breakpoints.up("md"));
    // Render. -------------------------------------------------------------------
    return (_createElement(Box, __assign({}, others, { key: id, sx: styleCard }),
        _jsxs(Grid, { container: true, justifyContent: "center", sx: { minHeight: "142px" }, children: [_jsx(Grid, { item: true, xs: 4, sm: 3, sx: {
                        p: 1.25,
                        height: "100%",
                        "& img": {
                            maxWidth: { xs: "100%", sm: "80px", md: "100%", lg: "120px" },
                            height: { xs: "76px", md: "100%" },
                            mt: { xs: 2.5, md: 0 }
                        }
                    }, children: image }), _jsxs(Grid, { item: true, xs: 8, sm: 9, container: true, direction: "column", justifyContent: "space-between", alignItems: "center", sx: { p: 0.5 }, children: [title && (_jsx(Grid, { item: true, children: _jsx(Typography, { variant: "h1", component: "div", sx: { mb: 1, color: "#000" }, children: title }) })), _jsxs(Grid, { item: true, container: true, direction: "row", justifyContent: "center", alignItems: "baseline", children: [subTitle1 && (_jsx(Grid, { item: true, children: _jsx(Typography, { variant: "h2", component: "div", sx: {
                                            mb: 1,
                                            fontSize: {
                                                xs: "2.0rem",
                                                lg: "2.5rem",
                                                fontWeight: "700",
                                                letterSpacing: "0.01em"
                                            },
                                            color: "primary.main"
                                        }, children: subTitle1 }) })), subTitle2 && (_jsx(Grid, { item: true, children: _jsx(Typography, { variant: "h3", component: "div", sx: {
                                            mb: 1,
                                            ml: 1,
                                            fontSize: {
                                                xs: "1.2rem",
                                                lg: "1.5rem",
                                                letterSpacing: "0.01em"
                                            },
                                            color: "primary.dark",
                                            textAlign: "center"
                                        }, children: subTitle2 }) }))] }), description && (_jsx(Grid, { item: true, children: _jsx(Typography, { variant: isMobile ? "subtitle2" : "h4", component: "div", sx: {
                                    textAlign: "center",
                                    mb: 1,
                                    color: "greyColor.main"
                                }, children: description }) }))] })] })));
};
export default PresentationBox;
