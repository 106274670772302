import { jsx as _jsx } from "react/jsx-runtime";
import SiteService from "cms/services/SiteService";
import generateTemplatePropsFromContents from "cms/utils/templatePropsUtils";
import { createContext } from "react";
import { useQuery } from "react-query";
var SiteContext = createContext({});
export default SiteContext;
export var SiteContextProvider = function (props) {
    var _a = props.site, site = _a === void 0 ? null : _a, _b = props.children, children = _b === void 0 ? null : _b;
    var data = useQuery("site", SiteService.get).data;
    // il est possible de passer un site props, cas du back office
    var currentSite = (typeof data === "object" && data.id && data) || site || {};
    if (currentSite && currentSite.contents) {
        var contentsProps = generateTemplatePropsFromContents(currentSite.contents);
        var settings = (contentsProps || {}).settings;
        currentSite.settings = settings;
    }
    return _jsx(SiteContext.Provider, { value: currentSite, children: children });
};
