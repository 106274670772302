var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import ErrorIcon from "@mui/icons-material/Error";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import { Box, Button, Grid, Typography } from "@mui/material";
import Loader from "cms/components/shared/Loader";
var boxStyled = {
    textAlign: "center",
    border: "1px solid",
    boxShadow: "0px 4px 8px rgba(102, 109, 146, 0.08)",
    p: "1.1rem",
    borderRadius: "7px",
    mt: "5rem",
    mb: "5rem",
    maxWidth: "320px",
    mr: "auto",
    ml: "auto",
    "& div, h4, h6": {
        mb: "8px"
    }
};
var iconStyled = {
    fontSize: "3rem",
    mb: "1rem"
};
var DisplayResult = function (props) {
    var loading = props.loading, sendingError = props.sendingError, handleReturn = props.handleReturn;
    return (_jsxs(Grid, { container: true, direction: "column", alignItems: "center", children: [loading && (_jsxs(Box, { sx: __assign(__assign({}, boxStyled), { borderColor: "info.main" }), children: [_jsx(Loader, {}), _jsx(Typography, { variant: "h4", children: "Envoi en cours" }), _jsx(Typography, { variant: "subtitle2", children: "Merci de patienter. Nous sommes en train d'envoyer votre message." })] })), !loading && (_jsxs(_Fragment, { children: [!sendingError && (_jsxs(Box, { sx: __assign(__assign({}, boxStyled), { borderColor: "success.main" }), children: [_jsx(TaskAltIcon, { sx: __assign({ color: "success.main" }, iconStyled) }), _jsx(Typography, { variant: "h4", children: "Message envoy\u00E9" }), _jsx(Typography, { variant: "subtitle2", children: "Nous avons bien re\u00E7u votre message, nous reviendrons vers vous si besoin dans les plus brefs delais." })] })), sendingError && (_jsxs(Box, { sx: __assign(__assign({}, boxStyled), { borderColor: "error.main" }), children: [_jsx(ErrorIcon, { sx: __assign({ color: "error.main" }, iconStyled) }), _jsx(Typography, { variant: "h4", children: "Echec de l'envoi" }), _jsx(Typography, { variant: "subtitle2", children: "Une erreur s'est produite lors de l'envoi de votre message, si le probl\u00E8me persiste veuillez contacter l'administrateur." })] })), _jsx(Button, { disabled: loading, onClick: handleReturn, children: "Revenir au formulaire" })] }))] }));
};
export default DisplayResult;
