import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Box from "@mui/material/Box";
var stepStyled = {
    ml: 1,
    borderLeft: "4px solid",
    borderColor: "secondary.dark",
    position: "relative",
    pl: 3,
    pb: 4
};
var bubbleStyled = {
    position: "absolute",
    left: "-12px",
    top: "-1px",
    display: "block",
    height: "20px",
    width: "20px",
    bgcolor: "secondary.dark",
    color: "white",
    borderRadius: "50%"
};
var TimelineStep = function (props) {
    var id = props.id, _a = props.title, title = _a === void 0 ? null : _a, _b = props.content, content = _b === void 0 ? null : _b;
    return (_jsxs(Box, { sx: stepStyled, children: [_jsx(Box, { component: "span", sx: bubbleStyled }), _jsx(Box, { sx: {
                    fontWeight: 600,
                    pb: 0.5
                }, children: title }), content] }, id));
};
export default TimelineStep;
