var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Icon from "cms/components/front-office/contents/Icon";
import Link from "cms/components/front-office/contents/Link";
var containerStyled = {
    position: "relative",
    p: 3,
    bgcolor: "secondary.light",
    "&:hover": {
        bgcolor: "secondary.main"
    }
};
var linkStyled = {
    fontWeight: "600",
    display: "inline-block",
    pt: 1,
    color: "primary.main",
    "& > *": {
        color: "primary.main"
    }
};
var iconStyled = {
    position: "absolute",
    top: 16,
    right: 16,
    "&> *": {
        color: "primary.main",
        fontSize: "1.5rem"
    }
};
var Attachment = function (props) {
    var _a = props.title, title = _a === void 0 ? null : _a, _b = props.description, description = _b === void 0 ? null : _b, _c = props.linkText, linkText = _c === void 0 ? null : _c, _d = props.file, file = _d === void 0 ? { name: "", url: "" } : _d, others = __rest(props, ["title", "description", "linkText", "file"]);
    var _e = file || {}, name = _e.name, url = _e.url;
    var id = others.id, othersProps = __rest(others, ["id"]);
    return (_jsxs(Box, __assign({ sx: containerStyled }, othersProps, { children: [title && (_jsx(Typography, { variant: "h5", sx: { mb: description ? 1 : 2, mt: 0 }, children: title })), description && _jsx(Typography, { sx: { mb: 2.5 }, children: description }), _jsx(Box, { sx: linkStyled, children: url ? (_jsx("a", { target: "_blank", rel: "noopener noreferrer", title: "Nouvel onglet : ".concat(name), href: url, style: { textDecoration: "none" }, download: name || true, children: linkText })) : (linkText) }), _jsx(Link, { url: url, external: true, children: _jsx(Box, { sx: iconStyled, children: _jsx(Icon, { icon: "eye", title: "Voir le document" }) }) })] })));
};
export default Attachment;
