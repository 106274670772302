import { jsx as _jsx } from "react/jsx-runtime";
import Accordion from "cms/components/front-office/contents/Accordion";
import Text from "cms/components/front-office/contents/Text";
import uuidv4 from "uuid/v4";
var details = _jsx(Text, { children: "Dans cette zone vous serez libre d'ajouter diff\u00E9rents contenus" });
var panels = new Array(3).fill("").map(function (p, i) { return ({
    summary: "Panneau d\u00E9pliant ".concat(i + 1),
    details: details,
    id: uuidv4()
}); });
var AccordionPreview = function () {
    return _jsx(Accordion, { panels: panels });
};
export default AccordionPreview;
