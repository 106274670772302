import { jsx as _jsx } from "react/jsx-runtime";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Slide from "@mui/material/Slide";
import Icon from "cms/components/front-office/contents/Icon";
import SearchBar from "cms/components/front-office/contents/SearchBar";
var SearchBarHeader = function (props) {
    var isOpen = props.isOpen, toggle = props.toggle, isMobile = props.isMobile;
    var closeIcon = (_jsx(IconButton, { "aria-label": "Fermez la recherche", onClick: toggle, size: "large", children: _jsx(Icon, { icon: "times", type: "fal" }) }));
    return (_jsx(Slide, { direction: isMobile ? "up" : "left", in: isOpen, mountOnEnter: true, unmountOnExit: true, children: _jsx(Box, { sx: { width: "100%", mt: { xs: 3.25, lg: 0 } }, role: "search", children: _jsx(SearchBar, { closeIcon: closeIcon }) }) }));
};
export default SearchBarHeader;
