import { jsx as _jsx } from "react/jsx-runtime";
import { styled } from "@mui/system";
var dynamicPartStyled = {
    pt: { xs: 1.5, md: 4 }
};
var MainStyled = styled("main")(function (_a) {
    var theme = _a.theme;
    return ({
        position: "relative"
    });
});
var HomeTemplate = function (props) {
    var page = props.page;
    var contents = page.contents;
    var dynamicPart = contents.dynamicPart;
    return _jsx(MainStyled, { sx: dynamicPartStyled, children: dynamicPart });
};
export default HomeTemplate;
