import { jsx as _jsx } from "react/jsx-runtime";
import { Box, Grid, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React from "react";
import Glider from "react-glider";
import ArticleCard from "templates/articles/ArticleCard";
var ArticlesCarousel = function (_a) {
    var articles = _a.articles;
    React.useEffect(function () {
        if (window) {
            // eslint-disable-next-line global-require
            require("glider-js/glider.min.css");
        }
    }, []);
    var theme = useTheme();
    var isMobile = useMediaQuery(theme.breakpoints.down("md"));
    return (_jsx(Grid, { container: true, columnSpacing: isMobile ? 0 : 6, children: isMobile ? (_jsx(Glider, { skipTrack: true, draggable: true, slidesToScroll: 1, slidesToShow: "auto", dragVelocity: 1.5, itemWidth: 240, hasArrows: true, hasDots: true, children: _jsx("div", { className: "grider-track", children: articles.map(function (article, index) { return (_jsx(Box, { ml: index === 0 ? 0 : 1.25, mr: index === articles.length - 1 ? 0 : 1.25, children: _jsx(ArticleCard, { page: article }) }, article.id)); }) }) })) : (articles.map(function (article) { return _jsx(ArticleCard, { page: article }, article.id); })) }));
};
export default ArticlesCarousel;
