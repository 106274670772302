var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, useMediaQuery } from "@mui/material";
import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
var ElementGraphiqueArticle = function () {
    var _a = useState(8000), windowWidth = _a[0], setWindowWidth = _a[1];
    var mainContainer = {
        position: "relative",
        width: "".concat(windowWidth, "px"),
        left: "0px",
        transform: "rotateY(-180deg)"
    };
    var rectangleStyles = {
        position: "absolute",
        background: "#3B29AB"
    };
    useEffect(function () {
        var handleResize = function () { return setWindowWidth((1228 + window.innerWidth) / 2); };
        window.addEventListener("resize", handleResize);
        handleResize();
        return function () {
            window.removeEventListener("resize", handleResize);
        };
    }, []);
    var isScreenSizeValid = useMediaQuery("(min-width: 1434px)");
    var topBoxes = [
        { width: "45.93px", top: "15px" },
        { width: "45.93px", top: "19px" },
        { width: "45.93px", top: "23px" }
    ];
    var middleBoxes = [
        { width: "71.85px", top: "34px" },
        { width: "71.85px", top: "38px" },
        { width: "71.85px", top: "42px" }
    ];
    var bottomBoxes = [
        { width: "100px", top: "53px" },
        { width: "100px", top: "57px" },
        { width: "100px", top: "61px" }
    ];
    var generateBoxes = function (boxes) {
        return boxes.map(function (_a) {
            var width = _a.width, top = _a.top;
            return (_jsx(Box, { sx: __assign(__assign({}, rectangleStyles), { width: width, height: "1px", top: top, display: isScreenSizeValid ? "block" : "none" }) }, uuidv4()));
        });
    };
    return (_jsxs(Box, { sx: mainContainer, children: [generateBoxes(topBoxes), generateBoxes(middleBoxes), generateBoxes(bottomBoxes)] }, uuidv4()));
};
export default ElementGraphiqueArticle;
