var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
var AgendaElementGraphique = function () {
    var _a = useState(8000), windowWidth = _a[0], setWindowWidth = _a[1];
    var theme = useTheme();
    useEffect(function () {
        var handleResize = function () { return setWindowWidth((1228 + window.innerWidth) / 2); };
        window.addEventListener("resize", handleResize);
        handleResize();
        return function () {
            window.removeEventListener("resize", handleResize);
        };
    }, []);
    var isScreenSizeValid = useMediaQuery("(min-width: 1391px)");
    var mainRectangle = {
        boxSizing: "border-box",
        position: "absolute",
        width: "113.92px",
        height: "27.42px",
        border: "1px solid #3B29AB",
        borderRadius: theme.student ? "1000px" : "5px",
        transform: "matrix(0, -1, -1, 0, 0, 0)",
        display: isScreenSizeValid ? "block" : "none"
    };
    return (_jsxs(Box, { sx: {
            position: "relative",
            width: "".concat(windowWidth, "px")
        }, children: [_jsx(Box, { sx: __assign(__assign({}, mainRectangle), { left: "93.4%", top: "-7px", transform: "".concat(mainRectangle.transform, " translate(-95%, -45%)") }) }), _jsx(Box, { sx: __assign(__assign({}, mainRectangle), { left: "94%", top: "-2px", transform: "".concat(mainRectangle.transform, " translate(-95%, -50%)") }) }), _jsx(Box, { sx: __assign(__assign({}, mainRectangle), { left: "94.5%", top: "4px", background: "#3B29AB", transform: "".concat(mainRectangle.transform, " translate(-95%, -55%)") }) })] }));
};
export default AgendaElementGraphique;
