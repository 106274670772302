import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, Button, Grid, Link, Typography } from "@mui/material";
import { HashLink } from "react-router-hash-link";
import CookiesModal from "./CookiesModal";
import { useCookiesBanner } from "./hooks/useCookiesBanner";
import { CookiesWrapperStyle } from "./styles/CookiesBannerStyle";
var CookiesBanner = function (_a) {
    var _b = _a.isBo, isBo = _b === void 0 ? false : _b, name = _a.name;
    var _c = useCookiesBanner(), isDisabledBanner = _c.isDisabledBanner, handleCookiesModal = _c.handleCookiesModal, handleRefuseAll = _c.handleRefuseAll, handleAcceptAll = _c.handleAcceptAll;
    return (_jsxs(_Fragment, { children: [!isBo && !isDisabledBanner && (_jsx(CookiesWrapperStyle, { tabIndex: 0, children: _jsxs(Box, { className: "cookies-banner", children: [_jsx(Typography, { component: "span", variant: "h3", children: "\u00C0 propos des cookies d'".concat(name) }), _jsxs(Box, { sx: { my: { xs: 0.75, sm: 2 } }, children: [_jsx(Typography, { component: "span", sx: { color: "black", xs: { fontSize: "1rem" }, md: { fontSize: "inherit" } }, children: "Ce site web utilise exclusivement des cookies n\u00E9cessaires au fonctionnement du site et des cookies de mesure d'audience." }), _jsx(Typography, { component: "span", sx: { color: "black", xs: { fontSize: "1rem" }, md: { fontSize: "inherit" } }, children: "Ils sont anonymis\u00E9s et ne contiennent aucune de vos donn\u00E9es personnelles." }), _jsxs(Typography, { component: "span", sx: { xs: { fontSize: "1rem" }, color: "black", md: { fontSize: "inherit" } }, children: ["Nous conservons votre choix pendant 13 mois. Vous pouvez changer ce choix \u00E0 tout moment et pouvez consulter notre", " ", _jsx(Link, { component: HashLink, to: "/donnees-personnelles-et-cookies", children: "politique de confidentialit\u00E9\u00A0" }), "."] })] }), _jsxs(Grid, { container: true, textAlign: "center", justifyContent: { xs: "center", sm: "right" }, spacing: { xs: 0.5, sm: 2 }, children: [_jsx(Grid, { item: true, children: _jsx(Button, { onClick: handleCookiesModal, variant: "outlined", children: "Personnaliser" }) }), _jsx(Grid, { item: true, children: _jsx(Button, { onClick: handleRefuseAll, variant: "contained", children: "Tout refuser" }) }), _jsx(Grid, { item: true, children: _jsx(Button, { onClick: handleAcceptAll, variant: "contained", children: "Tout accepter" }) })] })] }) })), _jsx(CookiesModal, { handleCookiesModal: handleCookiesModal })] }));
};
export default CookiesBanner;
