var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, Button, Grid, Typography, useTheme } from "@mui/material";
// Components. -----------------------------------------------------------------
import ArticlesCarousel from "components/Articles/ArticlesCarousel";
import { useState } from "react";
import { useArticlesTagsByParent, useLastArticlesByParent } from "services/article.service";
import { studentStyledTag, tagStyled } from "templates/articles/ArticleCard";
import ElementGraphiqueArticle from "./ElementGraphiqueArticle";
import ElementGraphiqueArticleGauche from "./ElementGraphiqueArticleGauche";
var LastArticles = function (props) {
    var _a = useState(""), selectedTag = _a[0], setSelectedTag = _a[1];
    var theme = useTheme();
    var blog = props.blog, title = props.title;
    var parentPage = blog === null || blog === void 0 ? void 0 : blog.page;
    var parentId = parentPage === null || parentPage === void 0 ? void 0 : parentPage.id;
    var siteId = parentPage === null || parentPage === void 0 ? void 0 : parentPage.siteId;
    var filters = selectedTag ? "tags||cont||".concat(selectedTag) : "";
    var lastArticles = useLastArticlesByParent(parentId, siteId, [filters]).data;
    var _b = (lastArticles || {}).data, articles = _b === void 0 ? [] : _b;
    var _c = useArticlesTagsByParent(parentId, siteId).data, tags = _c === void 0 ? [] : _c;
    var articlesForListing = articles.slice(0, 3);
    // Render. -------------------------------------------------------------------
    return (_jsxs(_Fragment, { children: [_jsx(ElementGraphiqueArticleGauche, {}), _jsx(ElementGraphiqueArticle, {}), _jsxs(Box, { children: [_jsxs(Box, { sx: {
                            display: "flex",
                            flexDirection: { xs: "column", md: "row" },
                            justifyContent: "space-between",
                            mb: 2.5,
                            alignItems: { xs: "flex-start", md: "center" }
                        }, children: [_jsx(Typography, { variant: "h2", component: "p", children: title }), articlesForListing.length > 0 && (_jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: "12px", flexDirection: "row" }, children: [_jsx(Typography, { variant: "subtitle2", sx: { whiteSpace: "nowrap" }, children: "Filtrer par cat\u00E9gorie" }), _jsxs(Grid, { container: true, component: "ul", alignItems: "center", sx: { mr: "-7px", listStyleType: "none" }, children: [_jsx(Box, { component: "li", sx: theme.student
                                                    ? __assign(__assign({}, studentStyledTag), { background: selectedTag === "" ? "#FFFFFF" : null, boxShadow: selectedTag === "" ? "2px 3px 0px #3B29AB" : null }) : __assign(__assign({}, tagStyled), { background: selectedTag === "" ? "#3B29AB" : null }), children: _jsx(Button, __assign({ disableTouchRipple: true, sx: {
                                                        px: 1,
                                                        py: 0.5,
                                                        fontSize: "0.75rem",
                                                        lineHeight: "1",
                                                        color: theme.professor ? (selectedTag === "" ? "#F4F7FE" : "#3B29AB") : "#3B29AB",
                                                        zIndex: 1
                                                    }, onClick: function () { return setSelectedTag(""); } }, (selectedTag === "" ? { "aria-current": true } : {}), { children: "Tous" })) }), tags &&
                                                tags.length > 0 &&
                                                tags.map(function (tag) { return (_jsx(Box, { component: "li", sx: theme.student
                                                        ? __assign(__assign({}, studentStyledTag), { background: selectedTag === tag ? "#FFFFFF" : null, boxShadow: selectedTag === tag ? "2px 3px 0px #3B29AB" : null }) : __assign(__assign({}, tagStyled), { background: selectedTag === tag ? "#3B29AB" : null }), children: _jsx(Button, __assign({ disableTouchRipple: true, sx: {
                                                            px: 1,
                                                            py: 0.5,
                                                            fontSize: "0.75rem",
                                                            lineHeight: "1",
                                                            color: theme.professor ? (selectedTag === tag ? "#F4F7FE" : "#3B29AB") : "#3B29AB",
                                                            zIndex: 1
                                                        }, onClick: function () {
                                                            setSelectedTag(tag);
                                                        } }, (selectedTag === tag ? { "aria-current": true } : {}), { children: tag })) }, tag)); })] })] }))] }), articlesForListing.length > 0 ? (_jsx(ArticlesCarousel, { articles: articlesForListing })) : (_jsx(Typography, { variant: "h3", mb: 2.5, sx: { color: "#000", textAlign: "center" }, children: "Aucun article" }))] })] }));
};
export default LastArticles;
